"use client";

import { QuestionCourseItem$key } from "@generated/QuestionCourseItem.graphql";
import { graphql, useFragment } from "react-relay";
import { useCallback, useState } from "react";
import CourseItemVideoPlayer from "@/courses/components/CourseItemVideoPlayer";
import ReportItemButton from "@/courses/learn/components/ReportItemButton";
import NextItemButton from "@/courses/learn/components/NextItemButton";
import CourseItemAttachmentsSection from "@/courses/components/CourseItemAttachmentsSection";
import dynamic from "next/dynamic";
import ShowHintButton from "@/courses/learn/components/ShowHintButton";
import ShowAnswerButton from "@/courses/learn/components/ShowAnswerButton";
import { Button } from "@/components/ui/button";
import { QuestionMarkIcon, VideoIcon } from "@radix-ui/react-icons";
import { WrenchIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import CourseItemDescription from "@/courses/components/CourseItemDescription";
import { ok } from "assert";

const PlateEditor = dynamic(() => import("@/components/plate-editor"), {
  ssr: false,
});

interface Props {
  item: QuestionCourseItem$key;
  mode: "learn" | "store";
  courseContentsSide: "LEFT" | "RIGHT" | "NONE";
}

const QuestionCourseItem: React.FC<Props> = ({
  item,
  mode,
  courseContentsSide,
}) => {
  const data = useFragment(
    graphql`
      fragment QuestionCourseItem on QuestionCourseItem {
        id
        title
        description
        ...CourseItemVideoPlayer_progress
        video {
          ...CourseItemVideoPlayer_video
        }
        question
        answer
        hints
        ...CourseItemAttachmentsSection_attachments
      }
    `,
    item,
  );

  const [step, setStep] = useState<"question" | "solution">("question");
  const [shownHintCount, setShownHintCount] = useState<number>(0);

  const showNextHint = useCallback(() => {
    setShownHintCount((count) => count + 1);
  }, []);

  if (!data.video && !data.question) {
    return (
      <>
        {mode === "store" ? (
          <div className={cn("flex items-center gap-x-2")}>
            <h2 className="flex-1 truncate text-lg font-bold text-foreground">
              {data.title}
            </h2>
          </div>
        ) : null}
        <div
          className={cn("px-4 pt-4 text-center sm:pt-8", {
            "sm:pl-2 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
          })}
        >
          <WrenchIcon className="mx-auto size-12 text-primary" />
          <h3 className="mt-2 text-sm font-semibold text-foreground">
            Soru henüz yayınlanmadı.
          </h3>
          <p className="mt-1 text-sm text-foreground/60">
            Lütfen eğitmeninizle iletişime geçin.
          </p>
        </div>
        {mode === "learn" ? (
          <div
            className={cn("flex items-center gap-x-2 px-4 pt-4", {
              "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
            })}
          >
            <h2 className="flex-1 truncate text-lg font-bold text-foreground">
              {data.title}
            </h2>
            <ReportItemButton />
            <NextItemButton />
          </div>
        ) : null}
        <CourseItemDescription
          description={data.description}
          courseContentsSide={courseContentsSide}
        />
        <CourseItemAttachmentsSection
          attachments={data}
          courseContentsSide={courseContentsSide}
        />
      </>
    );
  }

  if (!data.question) {
    ok(data.video);

    return (
      <>
        {mode === "store" ? (
          <div className={cn("flex items-center gap-x-2")}>
            <h2 className="flex-1 truncate text-lg font-bold text-foreground">
              {data.title}
            </h2>
          </div>
        ) : null}
        <CourseItemVideoPlayer
          title={data.title}
          video={data.video}
          progress={data}
          itemId={data.id}
          mode={mode}
        />
        {mode === "learn" ? (
          <div
            className={cn("flex items-center gap-x-2 px-4 pt-4", {
              "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
            })}
          >
            <h2 className="flex-1 truncate text-lg font-bold text-foreground">
              {data.title}
            </h2>
            {/* TODO: add show answer & show hint button */}
            <ReportItemButton />
            <NextItemButton completeOnNext />
          </div>
        ) : null}
        <CourseItemDescription
          description={data.description}
          courseContentsSide={courseContentsSide}
        />
        <CourseItemAttachmentsSection
          attachments={data}
          courseContentsSide={courseContentsSide}
        />
      </>
    );
  }

  return (
    <>
      {mode === "store" ? (
        <div className={cn("flex items-center gap-x-2")}>
          <h2 className="flex-1 truncate text-lg font-bold text-foreground">
            {data.title}
          </h2>
        </div>
      ) : null}
      {step === "question" || !data.video ? (
        <PlateEditor value={data.question} readOnly dense />
      ) : (
        <CourseItemVideoPlayer
          title={data.title}
          video={data.video}
          progress={data}
          itemId={data.id}
        />
      )}
      {shownHintCount > 0 ? (
        <section className="px-4 pt-4">
          <h3 className="text-lg font-bold text-foreground">İpuçları</h3>
          <ol className="ml-5 list-decimal pt-2">
            {data.hints.slice(0, shownHintCount).map((hint, index) => (
              <li
                key={index}
                className="mt-4 border-b first:mt-0 last:border-b-0"
              >
                <PlateEditor value={hint} readOnly dense />
              </li>
            ))}
          </ol>
        </section>
      ) : null}
      <div
        className={cn(
          "flex flex-wrap items-center justify-end gap-2 px-4 pt-4",
          {
            "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
          },
        )}
      >
        {mode !== "store" ? (
          <h2 className="grow truncate font-bold text-foreground sm:text-lg">
            {data.title}
          </h2>
        ) : (
          <span className="grow" />
        )}
        <ShowHintButton
          numberOfHints={data.hints.length}
          numberOfShownHints={shownHintCount}
          onClick={showNextHint}
        />
        <ShowAnswerButton answer={data.answer} />
        {data.video &&
          (step === "question" ? (
            <Button variant="outline" onClick={() => setStep("solution")}>
              Çözüm<span className="hidden sm:inline">ü Göster</span>
              <VideoIcon className={"ml-2 size-4"} />
            </Button>
          ) : (
            <Button variant="outline" onClick={() => setStep("question")}>
              Soru<span className="hidden sm:inline">yu Göster</span>
              <QuestionMarkIcon className={"ml-2 size-4"} />
            </Button>
          ))}
        {mode === "learn" && <NextItemButton completeOnNext />}
      </div>
      {mode === "learn" && (
        <div
          className={cn("flex items-center gap-x-2 px-4 pt-2", {
            "sm:pl-0 sm:pr-6 lg:pr-8": courseContentsSide === "LEFT",
          })}
        >
          <ReportItemButton />
        </div>
      )}
      {step === "solution" && (
        <CourseItemDescription
          description={data.description}
          courseContentsSide={courseContentsSide}
        />
      )}
      <CourseItemAttachmentsSection
        attachments={data}
        courseContentsSide={courseContentsSide}
      />
    </>
  );
};

export default QuestionCourseItem;
